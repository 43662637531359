@use '../util/breakpoints.scss' as *;
@use '../util/functions.scss' as *;

.hero {
    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    padding-top: rem(10);
    @include breakpoint-down(small){
        padding-top: rem(100);
    }
    height: 100%;
    a {
        text-transform: capitalize;
        margin-top: rem(20);
        font-size: rem(16);
        font-weight: 600;
        background-color: aqua;
        color: var(--black);
        padding: rem(9) rem(15);
        border-radius: rem(30);
        transition: all .3s ease-in-out;
        &:hover {
            background-color: rgb(13, 203, 203);
        }
    }
    &__container {
        @include breakpoint-up(large){
            padding: rem(50);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: rem(20);
            align-items: center;
        }
    }
    .text {
        padding: rem(10);
        margin-bottom: rem(15);
        line-height: 1.2;
        h2 {
            text-transform: capitalize;
            color: rgb(222, 164, 28);
            font-size: rem(47);
            width: 100%;
            @include breakpoint-down(small){
                font-size: rem(37);
                }
        }
        p {
            font-size: rem(20);
            margin-bottom: rem(35);
        }
    }
    .imgContainer {
        img {
            width: 95%;
            @include breakpoint-up(large){
            width: 50%;
            transform: translateX(13rem);
            }
        }
    }
}
