html {
    font-size: 100%;
    box-sizing: border-box;

}

*, *::before, *::after {
    box-sizing: inherit;
    list-style: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--pri-font);
    color: var(--white);
    overflow-x: hidden;
}
