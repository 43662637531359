// Breakpoints

// 640px, 1024px, 1400px (above)
$breakpoint-up: (
    "medium": "40em", 
    "large": "64em", 
    "x-large": 
    "87.5em"
    );

// 639px, 1023px, 1399px (down)
$breakpoint-down: (
    "small": "39.937em", 
    "medium": "63.9375em", 
    "large": "87.4375em"
    );

@mixin breakpoint-up($size) {
    @media (min-width: map-get($breakpoint-up, $size) ){
        @content
    }
};

@mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoint-down, $size) ){
        @content
    }
}
