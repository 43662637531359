:root {
  --pri-font:"Roboto", sans-serif;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --tw-blue: #1DA1F2;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  list-style: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--pri-font);
  color: var(--white);
  overflow-x: hidden;
}

h1, h2, h3 {
  margin-top: 0;
  margin-bottom: 0;
}

a, a:visited, a:active {
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.j-sb {
  justify-content: space-between;
}

.mg-tp {
  margin-top: rem(6);
}

.modal-active {
  animation: fade-in 0.3s linear forwards;
}

.fade-out {
  animation: fade-out 0.3s linear forwards;
}

@keyframes fade-in {
  0% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
.navbar {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4.7px);
  -webkit-backdrop-filter: blur(4.5px);
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 5;
}
.navbar__logo {
  display: flex;
  align-items: center;
  font-size: 1.5625rem;
  font-weight: 800;
  cursor: pointer;
}
.navbar__logo i {
  color: aqua;
  padding-right: 0.1875rem;
  font-size: 2.1875rem;
}
.navbar a {
  text-transform: capitalize;
  font-size: 0.8125rem;
  font-weight: 600;
  background-color: aqua;
  color: var(--black);
  padding: 0.5625rem 0.9375rem;
  border-radius: 1.875rem;
  transition: all 0.3s ease-in-out;
}
.navbar a:hover {
  background-color: rgb(13, 203, 203);
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.background {
  z-index: -2;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #15162e;
  overflow: hidden;
}

.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 19s linear infinite;
}

.background li:nth-child(0) {
  left: 24%;
  width: 175px;
  height: 175px;
  bottom: -175px;
  animation-delay: 1s;
}

.background li:nth-child(1) {
  left: 72%;
  width: 137px;
  height: 137px;
  bottom: -137px;
  animation-delay: 2s;
}

.background li:nth-child(2) {
  left: 33%;
  width: 134px;
  height: 134px;
  bottom: -134px;
  animation-delay: 8s;
}

.background li:nth-child(3) {
  left: 1%;
  width: 170px;
  height: 170px;
  bottom: -170px;
  animation-delay: 9s;
}

.background li:nth-child(4) {
  left: 52%;
  width: 149px;
  height: 149px;
  bottom: -149px;
  animation-delay: 6s;
}

.background li:nth-child(5) {
  left: 31%;
  width: 171px;
  height: 171px;
  bottom: -171px;
  animation-delay: 14s;
}

.background li:nth-child(6) {
  left: 24%;
  width: 100px;
  height: 100px;
  bottom: -100px;
  animation-delay: 30s;
}

.background li:nth-child(7) {
  left: 77%;
  width: 135px;
  height: 135px;
  bottom: -135px;
  animation-delay: 29s;
}

.background li:nth-child(8) {
  left: 8%;
  width: 177px;
  height: 177px;
  bottom: -177px;
  animation-delay: 2s;
}

.background li:nth-child(9) {
  left: 52%;
  width: 163px;
  height: 163px;
  bottom: -163px;
  animation-delay: 25s;
}

.containerHeader {
  padding: 1.03125rem;
  display: flex;
}

.padding {
  padding: 1rem;
}

.hero {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  padding-top: 0.625rem;
  height: 100%;
}
@media (max-width: 39.937em) {
  .hero {
    padding-top: 6.25rem;
  }
}
.hero a {
  text-transform: capitalize;
  margin-top: 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: aqua;
  color: var(--black);
  padding: 0.5625rem 0.9375rem;
  border-radius: 1.875rem;
  transition: all 0.3s ease-in-out;
}
.hero a:hover {
  background-color: rgb(13, 203, 203);
}
@media (min-width: 64em) {
  .hero__container {
    padding: 3.125rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
    align-items: center;
  }
}
.hero .text {
  padding: 0.625rem;
  margin-bottom: 0.9375rem;
  line-height: 1.2;
}
.hero .text h2 {
  text-transform: capitalize;
  color: rgb(222, 164, 28);
  font-size: 2.9375rem;
  width: 100%;
}
@media (max-width: 39.937em) {
  .hero .text h2 {
    font-size: 2.3125rem;
  }
}
.hero .text p {
  font-size: 1.25rem;
  margin-bottom: 2.1875rem;
}
.hero .imgContainer img {
  width: 95%;
}
@media (min-width: 64em) {
  .hero .imgContainer img {
    width: 50%;
    transform: translateX(13rem);
  }
}

.popup {
  z-index: 99;
  white-space: nowrap;
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0.4375rem;
  position: fixed;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
}
.popup .form {
  background-color: var(--white);
  color: var(--black);
  padding: 2.0625rem;
  align-items: center;
  text-align: center;
  height: fit-content;
  transform: translate(0%, 14%);
  border-radius: 0.3125rem;
  white-space: nowrap;
  z-index: 999;
}
.popup .form__header img {
  align-items: center;
  margin-bottom: -23px;
  margin-top: -23px;
  width: 2.5rem;
}
.popup .form__header h4 {
  font-size: 1.25rem;
  margin-bottom: -1px;
}
.popup .form .sign-in-with1 {
  cursor: pointer;
  margin-top: 0.875rem;
  margin-bottom: 0.625rem;
  background: transparent;
  border: 1px solid #7c7878;
  border-radius: 0.9375rem;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.popup .form .sign-in-with1 img {
  width: 1.9375rem;
}
.popup .form .sign-in-with1 span {
  font-size: 0.875rem;
}
.popup .form .sign-in-with1:hover {
  background: #e0dfdf;
}
.popup .form .sign-in-with {
  cursor: pointer;
  margin-bottom: 0.625rem;
  background: transparent;
  border: 1px solid #7c7878;
  border-radius: 0.9375rem;
  padding: 0.186875rem;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.popup .form .sign-in-with img {
  padding-right: 0.3125rem;
}
.popup .form .sign-in-with span {
  font-size: 0.875rem;
}
.popup .form .sign-in-with:hover {
  background: #e0dfdf;
}
.popup .form .or {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.375rem;
}
.popup .form .or::before {
  content: "";
  position: absolute;
  border: 0.1px solid #7c7878;
  width: 45%;
  display: flex;
  align-items: center;
  margin-left: -146px;
}
.popup .form .or::after {
  content: "";
  position: absolute;
  border: 1px solid #7c7878;
  width: 45%;
  display: flex;
  align-items: center;
  margin-left: 146px;
}
.popup .form__inputBox {
  background: #f5f8fa;
  outline: none;
  padding: 0.125rem;
  margin: 0.75rem;
  width: 100%;
  margin-right: 1.875rem;
  transform: translateX(-10px);
  position: relative;
  border-bottom: 2px solid #657786;
}
.popup .form__inputBox span {
  position: absolute;
  left: 0.375rem;
  font-weight: 400;
  color: #7c77a4;
}
.popup .form__inputBox input {
  width: 100%;
  padding: 0.375rem;
  font-size: 1.0625rem;
  background: transparent;
  border: none;
  outline: none;
}
.popup .form__button {
  outline: none;
  border: none;
  cursor: pointer;
  width: 103%;
  padding: 0.6875rem;
  border-radius: 1.25rem;
  margin: 0.3125rem;
  margin-left: -3px;
  font-size: 1.0625rem;
  font-weight: 500;
  background-color: #1da1f2;
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.popup .form__button:hover {
  background-color: #0b7aba;
}
.popup .form__bottomText {
  font-size: 0.96875rem;
  margin-top: 0.4375rem;
}
.popup .form__bottomText .fp {
  color: #1da1f2;
  cursor: pointer;
}